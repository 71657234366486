import React, { useState, useEffect } from 'react';
import './App.css';
import Home from './components/pages/Home';
import HomePortuguesePortugal from './components/pages/PortuguesePortugal';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBMZdOAoNV1KfPakZxC-q6cjZdJoZHXI0s",
  authDomain: "perguntasflamingo-com.firebaseapp.com",
  projectId: "perguntasflamingo-com",
  storageBucket: "perguntasflamingo-com.appspot.com",
  messagingSenderId: "1073781515404",
  appId: "1:1073781515404:web:a4f62765fb4303572b07a9",
  measurementId: "G-V0N7784QN1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const RedirectToExternalSite = ({ url }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null;
};


function App() {

  return (
    <>
      <Helmet>
        <meta name="description" content="Bem-vindo ao Cartas Flamingo!" />
        <meta name="apple-itunes-app" content="app-id=1537890432, app-argument=https://apps.apple.com/app/apple-store/id1537890432?pt=122101512&ct=website&mt=8" />
        <link rel="apple-touch-icon" sizes="180x180" href="/lottie/180.png" />
        <link rel="apple-touch-icon" sizes="32x32" href="/lottie/32.png" />
        <link rel="apple-touch-icon" sizes="16x16" href="/lottie/16.png" />
      </Helmet>
      <Router>
        <Switch>
          <Route path='/' exact component={HomePortuguesePortugal} />
          {/* <Route path='/es' exact component={HomePortuguesePortugal} /> */}
          {/* <Route path='/services' component={Services} />
          <Route path='/products' component={Products} />
          <Route path='/sign-up' component={SignUp} /> */}
        </Switch>
      </Router>
    </>
  );
}

export default App;
